import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooterNew from '../CaseFooterNew';
import HelmetSite from '../HelmetSite';
import './style.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "../../../../node_modules/swiper/swiper-bundle.min.css";

class Neshalashi extends Component{
  swiperRef = React.createRef();
  swiperRef2 = React.createRef();
  state = {
		preview: true,
		play: false,
		mobile: window.innerWidth <= 1000
	};

	resize = () => this.setState({mobile: window.innerWidth <= 1000})

	componentDidMount(){
    var video = document.getElementById("video");
    $(".videoBlock__btn").click(function () {
      $(".videoBlock__btn").toggleClass("videoBlock__btn_hidden");
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    });
		if(this.props.pathname == '/projects/neshalashi/'){
			this.setState({preview: false});
			this.props.setProjectName('neshalashi');
			$('body').addClass('case-expanded hash-neshalashi');
			$(window).scrollTop($('#case-neshalashi').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-neshalashi');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-neshalashi');
		}
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
	render(){
		return(
		<div className='case'>
			<div className="case-content" id="case-neshalashi">

        <div className="screen">
          <div className="topScreen">
            <div className="topScreen__bg" style={{backgroundImage: `url('/cases/img/neshalashi/bg-top.webp')`}} />
            <div className="topScreen__title"><img src="/cases/img/neshalashi/logo.svg" alt="" /></div>
          </div>
          <div className="aboutProject">
            <div className="content">
              <div className="aboutProject__title title">Лесной отель “Нешалаши”</div>
              <div className="aboutProject__desc">
                <div>
                  <div>Клиент</div>
                  <div>Invitek</div>
                </div>
                <div>
                  <div>Виды&nbsp;работ</div>
                  <div>
                    <div className="tabs">
                      <div className="tabs__tab tabs__tab_active">Фирменный&nbsp;стиль</div>
                      <div className="tabs__tab tabs__tab_active">Сайт</div>
                      <div>Нейминг</div>
                      <div>Платформа бренда</div>
                    </div>
                  </div>
                </div>            
              </div>          
            </div>
          </div>
        </div>
        <div className="screen workType">
          <div className="content">
            <div className="workType__wrap">
              <div className="workType__title title">Фирменный стиль</div>
              <div className="workType__desc text">
                Разработка фирменного стиля для жилого комплекса, находящегося на стыке классов комфорт+ и бизнес, требует особого подхода.
                <br/><br/>
                Основой графической концепции стали мотивы песчаных волн на дюнах, создающие динамичное и мягкое восприятие. 
                <br/><br/>
                В стиле используются три базовых цвета — золото, серебро и бронза. Эти оттенки взяты из архитектурных решений комплекса и помогают гибко передавать уровень класса объекта, от теплого уюта до сдержанной элегантности.
              </div>
            </div>
          </div>
        </div>
        <div className="screen">
          <div className="gridBox gridBox_style">
            <div className="gridBox__item gridBox__item_1">
              <div className="gridChild">
                <div className="gridChild__item gridChild__item_1"><img src="/cases/img/neshalashi/logo1.svg" alt="" /></div>
                <div className="gridChild__item gridChild__item_2"><img src="/cases/img/neshalashi/logo2.svg" alt="" /></div>             
              </div>
            </div>
            <div className="gridBox__item gridBox__item_2" style={{backgroundImage: `url('/cases/img/neshalashi/img1.webp')`}}></div>
            <div className="gridBox__item gridBox__item_3" style={{backgroundImage: `url('/cases/img/neshalashi/img2.webp')`}}></div>
            <div className="gridBox__item gridBox__item_4" style={{backgroundImage: `url('/cases/img/neshalashi/img3.webp')`}}></div>
            <div className="gridBox__item gridBox__item_5" style={{backgroundImage: `url('/cases/img/neshalashi/img4.webp')`}}></div>
            <div className="gridBox__item gridBox__item_6" style={{backgroundImage: `url('/cases/img/neshalashi/img5.webp')`}}>
              <div className="wordsBlock">
                <div className="wordsBlock__item">
                  <div className="wordsBlock__title">Тональность</div>
                  <div className="wordsBlock__text">
                    <div>Дружелюбный</div>
                    <div>Искренний</div>
                    <div>Доверительный</div>
                    <div>Уважительный</div>
                    <div>Ненавязчивый</div>
                  </div>
                </div>
                <div className="wordsBlock__item">
                  <div className="wordsBlock__title">Характер</div>
                  <div className="wordsBlock__text">
                    <div>Эмоциональный</div>
                    <div>Чувственный</div>
                    <div>Природный</div>
                    <div>Комфортный</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gridBox__item gridBox__item_7" style={{backgroundImage: `url('/cases/img/neshalashi/img6.webp')`}}></div>
            <div className="gridBox__item gridBox__item_8" style={{backgroundImage: `url('/cases/img/neshalashi/img7.webp')`}}></div>
            <div className="gridBox__item gridBox__item_9">
              <div className="colors">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="gridBox__item gridBox__item_10" style={{backgroundImage: `url('/cases/img/neshalashi/img8.webp')`}}>
              {!this.state.mobile && (<video playsInline autoPlay poster="/cases/img/neshalashi/img8.webp" muted loop>
                <source src="/cases/img/neshalashi/video_bg.mp4" type="video/mp4" />
              </video>)}
            </div>
            <div className="gridBox__item gridBox__item_11" style={{backgroundImage: `url('/cases/img/neshalashi/img9.webp')`}}></div>
            <div className="gridBox__item gridBox__item_12" style={{backgroundImage: `url('/cases/img/neshalashi/img10.webp')`}}></div>
            <div className="gridBox__item gridBox__item_13">
              <div className="gridChild2">
                <div className="gridChild2__item gridChild2__item_1" style={{backgroundImage: `url('/cases/img/neshalashi/img11.webp')`}}></div>          
                <div className="gridChild2__item gridChild2__item_2">
                  <img src="/cases/img/neshalashi/img12_1.svg" alt="" />
                  <img src="/cases/img/neshalashi/img12_2.svg" alt="" />
                </div>          
                <div className="gridChild2__item gridChild2__item_3">
                  <img src="/cases/img/neshalashi/img13_1.svg" alt="" />
                  <img src="/cases/img/neshalashi/img13_2.svg" alt="" />
                </div>          
              </div>
            </div>
            <div className="gridBox__item gridBox__item_14">
              <div className="slider1">
                <Swiper 
                  slidesPerView={'auto'} 
                  spaceBetween={this.state.mobile ? 26:26} 
                  className="" 
                  ref={this.swiperRef}
                  loop={true}
                >
                  <SwiperSlide>
                    <img src="/cases/img/neshalashi/img14.webp" alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/cases/img/neshalashi/img15.webp" alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/cases/img/neshalashi/img16.webp" alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/cases/img/neshalashi/img17.webp" alt="" />
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="slider2">
                <Swiper 
                  slidesPerView={'auto'} 
                  spaceBetween={this.state.mobile ? 26:26} 
                  className="" 
                  ref={this.swiperRef2}
                  loop={true}
                >
                  <SwiperSlide>
                    <img src="/cases/img/neshalashi/img18.webp" alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/cases/img/neshalashi/img19.webp" alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/cases/img/neshalashi/img20.webp" alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/cases/img/neshalashi/img21.webp" alt="" />
                  </SwiperSlide>
                </Swiper>
              </div>              
            </div>
            <div className="gridBox__item gridBox__item_15" style={{backgroundImage: `url('/cases/img/neshalashi/img22.webp')`}}></div>
            <div className="gridBox__item gridBox__item_16">
              <img src="/cases/img/neshalashi/img23.svg" alt="" />
            </div>
            <div className="gridBox__item gridBox__item_17" style={{backgroundImage: `url('/cases/img/neshalashi/img24.webp')`}}></div>
            <div className="gridBox__item gridBox__item_18" style={{backgroundImage: `url('/cases/img/neshalashi/img25.webp')`}}></div>
            <div className="gridBox__item gridBox__item_19">
              <img src="/cases/img/neshalashi/img26.svg" alt="" />
              <img src="/cases/img/neshalashi/img27.svg" alt="" />
              <img src="/cases/img/neshalashi/img28.svg" alt="" />
            </div>
            <div className="gridBox__item gridBox__item_20" style={{backgroundImage: `url('/cases/img/neshalashi/img29.webp')`}}></div>
          </div>
        </div>
        <div className="screen workType">
          <div className="content">
            <div className="workType__wrap">
              <div className="workType__title title">Сайт</div>
              <div className="workType__desc text">
                Сайт жилого комплекса полностью поддерживает и&nbsp;развивает фирменный стиль, используя графические мотивы и цветовую палитру, отражающую архитектуру проекта. 
                <br/><br/>
                Информация подана ярко и структурировано, что позволяет пользователям легко погружаться в&nbsp;атмосферу комплекса. 
                <br/><br/>
                Удобный интерфейс обеспечивает простой и&nbsp;интуитивный доступ ко всем разделам, делая взаимодействие с сайтом комфортным для каждого посетителя.
              </div>
            </div>
          </div>
        </div>
        <div className="screen">
          <div className="gridBox gridBox_site">
            <div className="gridBox__item gridBox__item_1"><img src="/cases/img/neshalashi/img30.webp" alt="" /></div>
            <div className="gridBox__item gridBox__item_2"><img src="/cases/img/neshalashi/img31.webp" alt="" /></div>
            <div className="gridBox__item gridBox__item_3" style={{backgroundImage: `url('/cases/img/neshalashi/img32.webp')`}}></div>
            <div className="gridBox__item gridBox__item_4"><img src="/cases/img/neshalashi/img33.svg" alt="" /></div>
            <div className="gridBox__item gridBox__item_5" style={{backgroundImage: `url('/cases/img/neshalashi/img34.webp')`}}>
              {!this.state.mobile && (<video playsInline autoPlay poster="/cases/img/neshalashi/img34.webp" muted loop>
                <source src="/cases/img/neshalashi/video_bg2.mp4" type="video/mp4" />
              </video>)}
              <div className="text">Это уникальный опыт проживания в&nbsp;максимальной близости к&nbsp;диким местам, но&nbsp;при этом с&nbsp;невероятным уровнем комфорта, развитой инфраструктурой и&nbsp;безукоризнен-ным сервисом.</div>
              <img src="/cases/img/neshalashi/logo3.svg" alt="" />
            </div>
            <div className="gridBox__item gridBox__item_6" style={{backgroundImage: `url('/cases/img/neshalashi/img35.webp')`}}></div>
            <div className="gridBox__item gridBox__item_7"><img src="/cases/img/neshalashi/img36.webp" alt="" /></div>
            <div className="gridBox__item gridBox__item_8"><img src="/cases/img/neshalashi/img37.webp" alt="" /></div>
            <div className="gridBox__item gridBox__item_9">
              <div className="videoBlock">
                <div className="videoBlock__btn" style={{ backgroundImage: `url('/cases/img/neshalashi/play.svg')` }}></div>
                <video id="video" poster="/cases/img/neshalashi/poster.webp" loop preload="metadata">
                  <source src="/cases/img/neshalashi/video.mp4" type="video/mp4"></source>
                </video>
              </div>                
            </div>
            <div className="gridBox__item gridBox__item_10"><img src="/cases/img/neshalashi/img38.webp" alt="" /></div>
            <div className="gridBox__item gridBox__item_11"><img src="/cases/img/neshalashi/img39.webp" alt="" /></div>
            <div className="gridBox__item gridBox__item_12" style={{backgroundImage: `url('/cases/img/neshalashi/img40.webp')`}}></div>
            <div className="gridBox__item gridBox__item_13">
              <div className="gridSmall">
                <div className="gridSmall__img"><img src="/cases/img/neshalashi/img41.webp" alt="" /></div>
                <div className="gridSmall__text text">
                  Мобильная версия сайта жилого комплекса полностью повторяет функционал основной версии, обеспечивая пользователям доступ ко всем необходимым функциям в любое время и в любом месте. 
                  <br/><br/>
                  Интерфейс оптимизирован для удобства использования на мобильных устройствах, что делает навигацию простой и интуитивной. При этом визуальный дизайн сохраняет эстетическую привлекательность, отражая фирменный стиль проекта и создавая гармоничное восприятие даже на&nbsp;небольших экранах.
                </div>
              </div>
            </div>
            <div className="gridBox__item gridBox__item_14">
              <img src="/cases/img/neshalashi/img42.webp" alt="" />
              <img src="/cases/img/neshalashi/img43.webp" alt="" />
              <img src="/cases/img/neshalashi/img44.webp" alt="" />
              <img src="/cases/img/neshalashi/img45.webp" alt="" />
            </div>
          </div>
        </div>
			</div>

  

    <CaseFooterNew 
				to={ this.props.next ? this.props.next.url: "/projects/neshalashi/"  }
				title= { this.props.next ? this.props.next.name: `Лесной отель “Нешалаши”`   } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/neshalashi/neshalashi.webp" }
        full={true}
			/>
      <HelmetSite title="Инград" />

			<div className="case-info">
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Лесной отель “Нешалаши”</div>
				<div className="tags"><span>фирменный стиль</span><span>сайт</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Neshalashi)